<script setup lang="ts">
import type { Image, Video } from '@design-system/components/UI/UIMedia.props'
import type { HomepageBrandCardWrapper } from './HomepageBrandCardWrapper.props'

const props = defineProps<HomepageBrandCardWrapper>()

const mainMedia = computed<Image | Video>(() => {
  const { mainMedia } = props.card
  return createMedia(mainMedia.type, mainMedia.src, mainMedia.alt)
})

const hoverMedia = computed<Image | Video>(() => {
  const { hoverMedia } = props.card
  return createMedia(hoverMedia.type, hoverMedia.src, hoverMedia.alt)
})

const onlyImgMedia = computed(
  () => mainMedia.value.type === 'image' && hoverMedia.value.type === 'image'
)

const target = ref(null)
const targetIsVisible = ref(false)

const { stop } = useIntersectionObserver(
  target,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting
  },
  { threshold: [0.5, 1] }
)

const fetchPriorityImageByIntersection = computed(() => {
  return targetIsVisible.value || props.fetchPriorityImage
})

onUnmounted(() => {
  stop()
})
</script>

<template>
  <div
    ref="target"
    class="brand-card-wrapper homepage-card-height card-container relative overflow-hidden"
  >
    <HomepageCard
      class="card main-media-card !block w-full"
      :class="onlyImgMedia ? 'block' : 'hidden'"
      :link="{ ...card.link, outlineOffset: false }"
      :brand="card.brand"
      :media-type="card.mainMedia.type"
      :media="mainMedia"
      :hover-media="hoverMedia"
      :fetch-priority-image="fetchPriorityImageByIntersection"
      :video-dark-overlay="card.hoverLogo && card.hoverLogo.overlayBackground"
      :is-portrait-card="isPortraitCard"
      :is-playable="targetIsVisible"
    >
      <template #media-logo>
        <HomepageBrandCardMediaLogo
          v-if="card.hoverLogo"
          :media-logo-type="card.hoverLogo.type"
          :brand="card.brand"
          :blend-mode="card.hoverLogo.color === 'blend'"
        />
      </template>
      <template #banner-logo>
        <HomepageCardBannerLogo
          :brand="card.brand"
          :banner-logo-type="card.bannerLogo.type"
          class="bg-primitives-white"
        />
      </template>
      <template #banner-text>
        <HomepageCardBannerLabel
          :first-label="card.firstLabel"
          :second-label="card.secondLabel"
          no-padding-labels
        />
      </template>
    </HomepageCard>
  </div>
</template>
